import React from 'react';
import styled from 'styled-components';

import Slider from './Slider';
import Dots from './Dots';
import callApi from '../../../services/api';
import isMobile from '../../../services/isMobile';
import {
  mobileThresholdPixels, margins, fontSizes, colors, Title2, EmphasisText, Content,
} from './StyledComponents';

import leftArrow from '../../../assets/arrow.left.navy.svg';
import rightArrow from '../../../assets/arrow.right.navy.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  margin: ${margins.l} 0px;
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 250px;
  height: 250px;
  background-color: ${colors.white};
  color: ${colors.navy};
  font-size: ${fontSizes.m};
  padding: ${margins.s};
  margin: ${margins.l} ${margins.s};
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  ${(props) => props.isCurrentFeedback && `
    width: 400px;
    height: 400px;
    font-size: ${fontSizes.xl};
  `}
`;

const TextContainer = styled.div`text-align: center;`;
const AuthorContainer = styled.div``;

const ControlContainer = styled.div``;
const ControlImg = styled.img`
  cursor: pointer;
  width: 30px;
  margin: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 15px;
    margin: ${margins.s};
  }
`;

class CustomersFeedbacks extends React.Component {
  constructor() {
    super();
    this.state = { feedbacks: [], currentFeedbackId: null };
  }

  componentDidMount() {
    callApi('public/dashboard').then(({ feedbacks }) => {
      const feedbacksIt = feedbacks.filter((feedback) => feedback.comment != null);
      this.setState({ feedbacks: feedbacksIt, currentFeedbackId: feedbacksIt[1].id });
    });
  }

  changeCurrentFeedbackId(newId) {
    const { feedbacks, currentFeedbackId } = this.state;
    const newIndex = feedbacks.findIndex((feedback) => feedback.id === newId);
    if (feedbacks[this.track.nextIndex(newIndex)].id === currentFeedbackId) this.track.prev();
    else if (feedbacks[this.track.prevIndex(newIndex)].id === currentFeedbackId) this.track.next();
    this.setState({ currentFeedbackId: newId });
  }

  prev() {
    const { feedbacks, currentFeedbackId } = this.state;
    const currentFeedbackIndex = feedbacks.findIndex((feedback) => feedback.id === currentFeedbackId);
    this.track.prev();
    const newId = feedbacks[this.track.prevIndex(currentFeedbackIndex)].id;
    this.setState({ currentFeedbackId: newId });
  }

  next() {
    const { feedbacks, currentFeedbackId } = this.state;
    const currentFeedbackIndex = feedbacks.findIndex((feedback) => feedback.id === currentFeedbackId);
    this.track.next();
    const newId = feedbacks[this.track.nextIndex(currentFeedbackIndex)].id;
    this.setState({ currentFeedbackId: newId });
  }

  render() {
    const { feedbacks, currentFeedbackId } = this.state;
    return (
      <Container>
        <Content>
          <Dots big />
          <Title2 big>Ils ont aimé</Title2>
          <EmphasisText center>9.3 / 10 sur 271 avis</EmphasisText>
          {feedbacks.length !== 0
            && (
              <SubContainer>
                <ControlContainer>
                  <ControlImg src={leftArrow} onClick={() => this.prev()} />
                </ControlContainer>
                <Slider
                  infinite
                  preserveState
                  viewsToShow={isMobile() ? 1 : 3}
                  ref={(c) => { this.track = c; }}
                  sliderStyle={{ alignItems: 'center' }}
                >
                  {feedbacks.map((feedback) => (
                    <FeedbackContainer
                      key={feedback.id}
                      isCurrentFeedback={feedback.id === currentFeedbackId}
                      onClick={() => this.changeCurrentFeedbackId(feedback.id)}
                    >
                      <TextContainer>{feedback.comment}</TextContainer>
                      <AuthorContainer>
                        {feedback.rating}
                        {' '}
                        / 10
                      </AuthorContainer>
                    </FeedbackContainer>
                  ))}
                </Slider>
                <ControlContainer>
                  <ControlImg src={rightArrow} onClick={() => this.next()} />
                </ControlContainer>
              </SubContainer>
            )}
        </Content>
      </Container>
    );
  }
}

export default CustomersFeedbacks;
